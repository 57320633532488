<template>
    <div class="first-confirm-page">
        <GlobalHeader />
        <CenteredLayout>
            <div class="first-confirm">
                <div class="form-description">
                    <p>
                        ※ブラウザの「戻る」「進む」ボタンはご使用にならないようお願いいたします。
                    </p>
                    <p>以下の内容を確認後「次へ」を押してください。</p>
                </div>

                <FormSection>
                    <!-- ネクスウェイ担当営業名 -->
                    <FormFieldGroup>
                        <template #title>
                            <!-- eslint-disable vue/no-v-html -->
                            <span
                                class="display-contents"
                                v-html="labels.nxwSalesPerson.name"
                            ></span>
                            <!-- eslint-enable vue/no-v-html -->
                        </template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.nxwSalesPerson.name }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- ネクスウェイ担当営業のメールアドレス -->
                    <FormFieldGroup>
                        <template #title>
                            <!-- eslint-disable vue/no-v-html -->
                            <span
                                class="display-contents"
                                v-html="labels.nxwSalesPerson.email"
                            ></span>
                            <!-- eslint-enable vue/no-v-html -->
                        </template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.nxwSalesPerson.email }}
                            </p>
                        </template>
                    </FormFieldGroup>
                </FormSection>

                <FormSection title="申込情報">
                    <!-- 申込み種別 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.applicationInfo.registrationType
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.applicationInfo.registrationType }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 申込み商品 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.applicationInfo.product
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ prodName(state.applicationInfo.product) }}
                            </p>
                        </template>
                    </FormFieldGroup>
                </FormSection>

                <FormSection title="お客様情報">
                    <!-- 会社名 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.companyName
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ actualCompanyName }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 会社名フリガナ -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.companyNameKana
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.companyNameKana }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- <FormFieldGroup>
                        <template #title>法人マイナンバー</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.houjinBangou }}
                            </p>
                        </template>
                    </FormFieldGroup> -->
                    <!-- 部署 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.department
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.department }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 役職・担当 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.position
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.position }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- お名前 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.name
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.nameLastName }}
                                {{ state.customerInfo.nameFirstName }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- お名前フリガナ -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.nameKana
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.nameKanaLastName }}
                                {{ state.customerInfo.nameKanaFirstName }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 電話番号 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.phoneNumber
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.phoneNumber }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- FAX番号 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.faxNumber
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.faxNumber }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- メールアドレス -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.email
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.email }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 郵便番号 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.postalCode
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{
                                    formattedPostalCode(
                                        state.customerInfo.postalCode
                                    )
                                }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 住所 -->
                    <FormFieldGroup>
                        <template #title>{{
                            labels.customerInfo.address
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.customerInfo.addrPref
                                }}{{ state.customerInfo.addrCity
                                }}{{ state.customerInfo.addrStreet
                                }}{{ state.customerInfo.addrOther }}
                            </p>
                        </template>
                    </FormFieldGroup>
                </FormSection>

                <FormSection
                    v-if="
                        productModel?.inputableCustomerId ||
                        productModel?.inputableLoginIdType ||
                        productModel?.inputableLoginInfoLastName ||
                        productModel?.inputableLoginInfoFirstName ||
                        productModel?.inputableLoginInfoComment
                    "
                    title="ログイン情報"
                >
                    <!-- お客様番号 -->
                    <FormFieldGroup v-if="productModel.inputableCustomerId">
                        <template #title>{{
                            labels.loginInfo.customerId
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.loginInfo.customerId }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 姓・名 -->
                    <FormFieldGroup
                        v-if="
                            productModel?.inputableLoginInfoLastName ||
                            productModel?.inputableLoginInfoFirstName
                        "
                    >
                        <template #title>{{ labels.loginInfo.name }}</template>
                        <template #content>
                            <p class="confirming-text">
                                <template
                                    v-if="
                                        productModel?.inputableLoginInfoLastName
                                    "
                                >
                                    {{ state.loginInfo.nameLastName }}
                                </template>
                                <template
                                    v-if="
                                        productModel?.inputableLoginInfoFirstName
                                    "
                                >
                                    &nbsp;{{ state.loginInfo.nameFirstName }}
                                </template>
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- メールアドレス -->
                    <FormFieldGroup v-if="productModel.loginIdTypeIsEmail">
                        <template #title>{{ labels.loginInfo.email }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.loginInfo.email }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- FAX番号 -->
                    <FormFieldGroup v-else-if="productModel.loginIdTypeIsFax">
                        <template #title>{{
                            labels.loginInfo.faxNumber
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.loginInfo.faxNumber }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 任意 -->
                    <FormFieldGroup
                        v-else-if="productModel.loginIdTypeIsOptional"
                    >
                        <template #title>{{
                            labels.loginInfo.otherIdCode
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.loginInfo.otherIdCode }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- 請求内訳コメント -->
                    <FormFieldGroup
                        v-if="productModel?.inputableLoginInfoComment"
                    >
                        <template #title>{{
                            labels.loginInfo.comment
                        }}</template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.loginInfo.comment }}
                            </p>
                        </template>
                    </FormFieldGroup>
                </FormSection>

                <FormSection title="承認者情報">
                    <!-- 部署・氏名 -->
                    <FormFieldGroup>
                        <template #title>
                            <span
                                class="display-contents"
                                v-html="labels.approverInfo.deptOrName"
                            ></span>
                        </template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.approverInfo.deptOrName }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- メールアドレス -->
                    <FormFieldGroup>
                        <template #title>
                            <span
                                class="display-contents"
                                v-html="labels.approverInfo.email"
                            ></span>
                        </template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.approverInfo.email }}
                            </p>
                        </template>
                    </FormFieldGroup>

                    <br>

                    <!-- 部署・氏名2 -->
                    <FormFieldGroup>
                        <template #title>
                            <span
                                class="display-contents"
                                v-html="labels.approverInfo.deptOrName2"
                            ></span>
                        </template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.approverInfo.deptOrName2 }}
                            </p>
                        </template>
                    </FormFieldGroup>
                    <!-- メールアドレス2 -->
                    <FormFieldGroup>
                        <template #title>
                            <span
                                class="display-contents"
                                v-html="labels.approverInfo.email2"
                            ></span>
                        </template>
                        <template #content>
                            <p class="confirming-text">
                                {{ state.approverInfo.email2 }}
                            </p>
                        </template>
                    </FormFieldGroup>
                </FormSection>

                <div class="submit-button-container">
                    <BackButton @click="handleClickBack" />
                    <NextButton @click="handleClickNext" />
                </div>
            </div>
        </CenteredLayout>
        <GlobalFooter />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

import { ROUTE_PATHS } from "../../../../router/paths";
import { useResourcesStore } from "../../../../store/resources";
import { useWebFormStore } from "../../../../store/webform";
import BackButton from "../../../ui/Atoms/BackButton.vue";
import FormFieldGroup from "../../../ui/Atoms/FormFieldGroup.vue";
import GlobalFooter from "../../../ui/Atoms/GlobalFooter.vue";
import GlobalHeader from "../../../ui/Atoms/GlobalHeader.vue";
import NextButton from "../../../ui/Atoms/NextButton.vue";
import FormSection from "../../../ui/Molecures/FormSection.vue";
import CenteredLayout from "../../../ui/Templates/CenteredLayout.vue";

export default defineComponent({
    name: "FirstConfirmPage",
    components: {
        GlobalHeader,
        CenteredLayout,
        GlobalFooter,
        FormSection,
        FormFieldGroup,
        BackButton,
        NextButton,
    },
    props: {
        storybook: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const store = useWebFormStore();
        const state = computed(() => store.state);

        const actualCompanyName = store.actualCompanyName;
        const prodModel = store.prodModel;
        const labels = store.labels;

        const resourceStore = useResourcesStore();
        const getProductById = resourceStore.getProductById;

        return { state, getProductById, actualCompanyName, prodModel, labels };
    },
    computed: {
        productModel() {
            return this.prodModel();
        },
    },
    methods: {
        prodName(prodId?: string) {
            if (!prodId) {
                throw new Error(`prodId が空です。`);
            }
            return this.getProductById(prodId)?.name;
        },
        formattedPostalCode(code?: string) {
            if (!code) return code;
            if (!/^\d+$/.test(code)) return code;

            const list = [...code];
            list.splice(3, 0, "-");
            return list.join("");
        },
        handleClickBack() {
            if (this.$props.storybook) {
                alert("前のページへ戻る");
                return;
            }

            this.$router.push({ path: ROUTE_PATHS.SECOND_FORM });
        },
        handleClickNext() {
            if (this.$props.storybook) {
                alert("次の画面へ進む");
                return;
            }

            this.$router.push({ path: ROUTE_PATHS.SECOND_CONFIRM });
        },
    },
});
</script>

<style lang="scss" scoped>
.first-confirm-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-height: 100vh;

    .first-confirm {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;

        .form-description {
            margin: 0 0 2.25rem;
        }

        .confirming-text {
            font-size: 1em;
            margin: 0;
            min-height: 1rem;
        }
    }

    .submit-button-container {
        margin: 3rem 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;

        & > * {
            margin-bottom: 1rem;
        }
    }
}

.display-contents {
    display: contents;
}
</style>
