import {
    ApplicationInfoSection,
    ApproverInfoSection,
    BillingInfoSection,
    CustomerInfoSection,
    LoginInfoSection,
    NxwSalesPersonSection,
    PrivacyInfoSection,
} from "./interfaces";

type FormFieldLabels<K extends string> = Record<K, string>;

type FormLabels = {
    privacyInfo: FormFieldLabels<keyof PrivacyInfoSection | "agreeCheckbox">;
    nxwSalesPerson: FormFieldLabels<keyof NxwSalesPersonSection>;
    applicationInfo: FormFieldLabels<keyof ApplicationInfoSection>;
    customerInfo: FormFieldLabels<
        keyof CustomerInfoSection | "name" | "nameKana" | "address"
    >;
    loginInfo: FormFieldLabels<keyof LoginInfoSection | "name">;
    approverInfo: FormFieldLabels<keyof ApproverInfoSection>;
    billingInfo: FormFieldLabels<
        | keyof BillingInfoSection
        | "name"
        | "address"
        | "billingMethodByWeb"
        | "billingMethodBySnailMail"
        | "billingSendToTantou"
        | "billingSendToBillingEmail"
        | "billingSendToOther"
        | "paymentMethodByBankDebit"
        | "paymentMethodByTransferMoney"
        | "wantOptions"
    >;
};

/**
 * @package
 */
export const Labels: FormLabels = {
    privacyInfo: {
        agree: "個人情報の取り扱い",
        agreeCheckbox: "個人情報の取り扱いについて同意する",
    },
    nxwSalesPerson: {
        name: "<span>ネクスウェイ</span><span>担当営業名</span>",
        email: "<span>ネクスウェイ</span><span>担当営業の</span><span>メールアドレス</span>",
    },
    applicationInfo: {
        product: "お申し込みサービス",
        registrationType: "申込種別",
    },
    customerInfo: {
        companyName: "会社名",
        companyNameKana: "会社名フリガナ",
        department: "部署名",
        position: "役職・担当",
        name: "お名前",
        nameLastName: "姓",
        nameFirstName: "名",
        nameKana: "フリガナ",
        nameKanaLastName: "セイ",
        nameKanaFirstName: "メイ",
        phoneNumber: "電話番号",
        faxNumber: "FAX番号",
        email: "メールアドレス",
        postalCode: "郵便番号",
        address: "住所",
        addrPref: "都道府県",
        addrCity: "市区郡",
        addrStreet: "町名番地",
        addrOther: "建物・その他（任意）",
    },
    loginInfo: {
        copyFromCustomerInfo: "お客様情報からコピー",
        customerId: "お客様番号",
        name: "お名前",
        nameLastName: "姓",
        nameFirstName: "名",
        email: "メールアドレス",
        faxNumber: "FAX番号",
        otherIdCode: "メールアドレス", // 識別コードが "任意" の場合に使用する.
        comment: "請求内訳コメント",
    },
    approverInfo: {
        deptOrName: "＜お一人目＞<br>部署・氏名",
        email: "＜お一人目＞<br>メールアドレス",
        deptOrName2: "＜お二人目＞<br>部署・氏名",
        email2: "＜お二人目＞<br>メールアドレス",
    },
    billingInfo: {
        copyCustomerInfo: "ご担当者様と同じ",
        companyName: "会社名",
        companyNameKana: "会社名フリガナ",
        department: "部署名",
        name: "お名前",
        nameLastName: "姓",
        nameFirstName: "名",
        phoneNumber: "電話番号",
        postalCode: "郵便番号",
        address: "住所",
        addrPref: "都道府県",
        addrCity: "市区郡",
        addrStreet: "町名番地",
        addrOther: "建物・その他（任意）",
        email: "メールアドレス",
        // deptOrPlanNameOrEtc: "部署名、プラン名など",
        billingMethod: "請求方法",
        billingMethodByWeb: "Web請求",
        billingMethodBySnailMail: "郵送",
        billingSendTo: "請求宛先",
        billingSendToTantou: "ご担当者宛",
        billingSendToBillingEmail: "請求先のe-mailアドレス宛",
        billingSendToOther: "その他",
        otherEmail: "<span>その他</span><span>メールアドレス</span>",
        otherEmail2: "<span>その他</span><span>メールアドレス</span>",
        otherEmail3: "<span>その他</span><span>メールアドレス</span>",
        otherEmail4: "<span>その他</span><-span>メールアドレス</span>",
        otherEmail5: "<span>その他</span><span>メールアドレス</span>",
        paymentMethod: "支払方法",
        paymentMethodByBankDebit: "銀行引き落とし",
        paymentMethodByTransferMoney: "振込",
        wantOptions: "利用明細、請求内訳等",
        wantDownloadStatement:
            "利用明細（無料）のダウンロード（郵送の場合は同封）を希望",
        wantSplitWithStatementSplittingCode:
            "明細分割コード毎に分割（１枚ずつ作成）する",
    },
} as const;
