import { WebFormState } from "../interfaces";

/**
 * 請求先その他メールアドレス (1 ~ 5) に重複が含まれない場合に `true` を返す.
 *
 * @param {WebFormState} state
 * @param {keyof Pick<WebFormState['billingInfo'], 'otherEmail'|'otherEmail2'|'otherEmail3'|'otherEmail4'|'otherEmail5'>} fieldName
 * @return {boolean}
 * @package
 */
export const billingOtherEmailIsUniq = (
    state: WebFormState,
    fieldName: keyof Pick<
        WebFormState["billingInfo"],
        | "otherEmail"
        | "otherEmail2"
        | "otherEmail3"
        | "otherEmail4"
        | "otherEmail5"
    >
): boolean => {
    const targetValue = state.billingInfo[fieldName];
    if (!targetValue) {
        // 空の場合は確認不要なので `true`.
        return true;
    }

    const { otherEmail, otherEmail2, otherEmail3, otherEmail4, otherEmail5 } =
        state.billingInfo;

    const emailValues = [
        otherEmail,
        otherEmail2,
        otherEmail3,
        otherEmail4,
        otherEmail5,
    ];

    const sameValues = emailValues.filter((v) => v === targetValue);
    const isUniq = sameValues.length === 1;
    // console.debug({ fieldName, targetValue, emailValues, isUniq });
    return isUniq;
};

/**
 * 指定の otherEmail field の入力順序が守られている場合にのみ `true` を返す.
 *
 * @param {WebFormState} state
 * @param {keyof Pick<WebFormState['billingInfo'], 'otherEmail'|'otherEmail2'|'otherEmail3'|'otherEmail4'|'otherEmail5'>} fieldName
 * @return {boolean}
 * @package
 */
export const billingOtherEmailIsInOrdered = (
    state: WebFormState,
    fieldName: keyof Pick<
        WebFormState["billingInfo"],
        | "otherEmail"
        | "otherEmail2"
        | "otherEmail3"
        | "otherEmail4"
        | "otherEmail5"
    >
): boolean => {
    if (!state.billingInfo[fieldName]) {
        // 値が空の場合は確認が不要なので `true`.
        return true;
    }

    const { otherEmail, otherEmail2, otherEmail3, otherEmail4, otherEmail5 } =
        state.billingInfo;
    const emailValues = [
        otherEmail,
        otherEmail2,
        otherEmail3,
        otherEmail4,
        otherEmail5,
    ];

    const index = emailValues.findIndex(
        (value) => value === state.billingInfo[fieldName]
    );

    if (index === 0) return true;
    return !!emailValues[index - 1];
};

/**
 * 承認者1と承認者2のメールアドレスに重複が含まれない場合に `true` を返す.
 *
 * @param {WebFormState} state
 * @param {keyof Pick<WebFormState['approverInfo'], 'email'|'email2'>} fieldName
 * @return {boolean}
 * @package
 */
export const approverEmailIsUniq = (
    state: WebFormState,
    fieldName: keyof Pick<
        WebFormState["approverInfo"],
        | "email"
        | "email2"
    >
): boolean => {
    const targetValue = state.approverInfo[fieldName];
    if (!targetValue) {
        // 空の場合は確認不要なので `true`.
        return true;
    }

    const { email, email2 } =
        state.approverInfo;

    const emailValues = [
        email,
        email2,
    ];

    const sameValues = emailValues.filter((v) => v === targetValue);
    const isUniq = sameValues.length === 1;
    // console.debug({ fieldName, targetValue, emailValues, isUniq });
    return isUniq;
};
