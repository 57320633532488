import {
    type AnyObject,
    boolean as yupBoolean,
    type Maybe,
    object as yupObject,
    string as yupString,
    ValidationError,
} from "yup";

import { NOT_AVAILABLE } from "../../libs/company-types";
import {
    EMAIL_OPTIONAL_PATTERN_REGEXP,
    EMAIL_PATTERN_REGEXP,
    FAX_NUMBER_OPTIONAL_REGEXP,
    FAX_NUMBER_REGEXP,
    KATAKANA_REGEXP,
    KATAKANA_REGEXP_EXCLUDE_NUM,
    PHONE_NUMBER_REGEXP,
    POSTALCODE_REGEXP,
} from "../../libs/patterns";
import {
    ValidationBooleanSchema,
    ValidationObjectSchema,
    ValidationStringSchema,
    WebFormState,
    WebFormValidators,
} from "./interfaces";
import { Labels } from "./labels";
import { useWebFormStore } from "./store";
import {
    approverEmailIsUniq,
    billingOtherEmailIsInOrdered,
    billingOtherEmailIsUniq,
} from "./validators/helpers";

const boolean = <T extends Maybe<boolean> = boolean>() =>
    yupBoolean() as ValidationBooleanSchema<T>;

const string = <T extends Maybe<string> = string>() =>
    yupString() as ValidationStringSchema<T>;

const object = <T extends Maybe<AnyObject> = Maybe<AnyObject>>() =>
    yupObject() as ValidationObjectSchema<T>;

const OTHER_EMAILS_INPUT_ORDER_ERROR_MESSAGE =
    "メールアドレスは順番に入力して下さい。";

/**
 * @package
 */
export const validators: WebFormValidators = {
    privacyInfo: {
        agree: boolean().oneOf([true], "選択してください。"),
    },
    nxwSalesPerson: {
        // NXW担当営業名
        name: string()
            .trim()
            .required("入力は必須です。")
            .max(50, "50文字以内で入力してください。"),

        // NXW担当営業のメールアドレス
        email: string()
            .trim()
            .required("こちらの入力は必須です。")
            .email("入力形式が誤っています。")
            .matches(EMAIL_PATTERN_REGEXP, "入力形式が誤っています。")
            .max(80, "80文字以内で入力してください。"),
    },
    // 申込情報
    applicationInfo: {
        // 申込種別
        registrationType: string().trim().required("選択は必須です。"),

        // お申し込みサービス
        product: string().trim().required("選択は必須です。"),
    },
    // お客様情報
    customerInfo: {
        // 会社名
        companyName: object<
            | {
                  prefix?: string;
                  name?: string;
                  suffix?: string;
              }
            | undefined
        >()
            .shape({
                prefix: string().default(undefined),
                name: string().default(undefined),
                suffix: string().default(undefined),
            })
            .required("入力は必須です。")
            .test("companyNameTest", (values, ctx) => {
                console.debug("called companyNameTest");
                console.debug("values", values);
                const { prefix, name, suffix } = values;
                const p = prefix === NOT_AVAILABLE ? "" : prefix;
                const s = suffix === NOT_AVAILABLE ? "" : suffix;

                if (!prefix && !suffix) {
                    throw ctx.createError({
                        message: "法人格をいずれか片方選択してください。",
                    });
                }

                if (typeof name === "undefined" || name.length < 1) {
                    throw ctx.createError({
                        message: "会社名を入力してください。",
                    });
                }

                const actualCompanyName = `${p}${name}${s}`;
                if (actualCompanyName.length > 50) {
                    throw ctx.createError({
                        message: `法人格込みで50文字以内で入力してください。（現在: ${actualCompanyName.length}文字）`,
                    });
                }

                return true;
            }),
        // 会社名フリガナ
        companyNameKana: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(50, "最大50文字です。")
            .matches(KATAKANA_REGEXP, "入力形式が誤っています。"),

        // 法人マイナンバー
        // houjinBangou: string()
        //     .trim()
        //     .notRequired()
        //     .max(100, "最大100文字です。")
        //     .matches(
        //         /^([0-9-]+|不明|【不明】)?$/,
        //         "半角数値で入力してください。"
        //     ),

        // 部署名
        department: string().trim().max(50, "最大50文字です。"),

        // 役職・担当
        position: string().trim().max(50, "最大50文字です。"),

        // お名前
        nameLastName: string()
            .trim()
            .required("姓の入力は必須です。")
            .max(24, "最大24文字です。"),

        nameFirstName: string()
            .trim()
            .required("名の入力は必須です。")
            .max(24, "最大24文字です。"),

        // フリガナ
        nameKanaLastName: string()
            .trim()
            .required("セイの入力は必須です。")
            .max(24, "最大24文字です。")
            .matches(
                KATAKANA_REGEXP_EXCLUDE_NUM,
                "セイの入力形式が誤っています。"
            ),

        nameKanaFirstName: string()
            .trim()
            .required("メイの入力は必須です。")
            .max(24, "最大24文字です。")
            .matches(
                KATAKANA_REGEXP_EXCLUDE_NUM,
                "メイの入力形式が誤っています。"
            ),

        // 電話番号
        phoneNumber: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(11, "最大11文字です。")
            .matches(PHONE_NUMBER_REGEXP, "入力形式が誤っています。"),

        // FAX番号
        faxNumber: string()
            .trim()
            .max(11, "最大11文字です")
            .matches(FAX_NUMBER_OPTIONAL_REGEXP, "入力形式が誤っています。"),

        // メールアドレス
        email: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(80, "最大80文字です。")
            .matches(EMAIL_PATTERN_REGEXP, "入力形式が誤っています。"),

        // 郵便番号
        postalCode: string()
            .trim()
            .required("こちらの入力は必須です。")
            .min(7, "最小7文字です。")
            .max(8, "最大8文字です。")
            .matches(POSTALCODE_REGEXP, "入力形式が誤っています。"),

        // 住所
        addrPref: string().trim().required("こちらの入力は必須です。"),

        addrCity: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(100, "最大100文字です。"),

        addrStreet: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(50, "最大50文字です。"),

        addrOther: string().trim().max(50, "最大50文字です。"),
    },
    loginInfo: {
        copyFromCustomerInfo: boolean(),
        // お客様番号 (= お客様番号, C_Cus_Num__c)
        customerId: string<string | undefined>()
            .trim()
            .max(50, "最大50文字です。")
            .test("billingInfoCustomerIdTest", (value, context) => {
                const prodModel = useWebFormStore().prodModel();

                if (!prodModel) return;

                if (
                    prodModel.requiredCustomerId &&
                    (typeof value === "undefined" || value.length === 0)
                ) {
                    throw context.createError({
                        message: "こちらの入力は必須です。",
                    });
                }

                if (
                    prodModel.prohibitedCustomerId &&
                    typeof value !== "undefined" &&
                    value.length > 0
                ) {
                    throw context.createError({
                        message:
                            "プログラムエラー。入力不可の項目が表示されています。",
                    });
                }

                return true;
            }),

        nameLastName: string<string | undefined>()
            .default(undefined)
            .max(24)
            .test("loginInfoNameLastNameTest", (value, context) => {
                const prodModel = useWebFormStore().prodModel();
                if (!prodModel) return;

                if (prodModel.requiredLoginInfoLastName) {
                    if (typeof value === "undefined" || value.length === 0) {
                        throw context.createError({
                            message:
                                "「姓」の入力は必須です。" +
                                (context.options.context?.state.loginInfo
                                    .copyFromCustomerInfo
                                    ? `上の「お客様情報」セクションでご記入いただくか、「${Labels.loginInfo.copyFromCustomerInfo}」のチェックを外してご記入ください。`
                                    : ""),
                        });
                    } else {
                        return true;
                    }
                } else if (prodModel.prohibitedLoginInfoLastName) {
                    if (typeof value !== "undefined" && value.length > 0) {
                        throw context.createError({
                            message: "姓の入力は出来ません。",
                        });
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }),
        nameFirstName: string<string | undefined>()
            .default(undefined)
            .max(24)
            .test("loginInfoNameFirstNameTest", (value, context) => {
                const prodModel = useWebFormStore().prodModel();
                if (!prodModel) return;

                if (prodModel.requiredLoginInfoFirstName) {
                    if (typeof value === "undefined" || value.length === 0) {
                        throw context.createError({
                            message:
                                "「名」の入力は必須です。" +
                                (context.options.context?.state.loginInfo
                                    .copyFromCustomerInfo
                                    ? `上の「お客様情報」セクションでご記入いただくか、「${Labels.loginInfo.copyFromCustomerInfo}」のチェックを外してご記入ください。`
                                    : ""),
                        });
                    } else {
                        return true;
                    }
                } else if (prodModel.prohibitedLoginInfoFirstName) {
                    if (typeof value !== "undefined" && value.length > 0) {
                        throw context.createError({
                            message: "名の入力は出来ません。",
                        });
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }),
        faxNumber: string<string | undefined>()
            .matches(FAX_NUMBER_OPTIONAL_REGEXP, "入力形式が誤っています。")
            .max(50)
            .test("loginInfoFaxNumberTest", (value, context) => {
                const prodModel = useWebFormStore().prodModel();
                if (!prodModel) return;

                switch (prodModel.loginIdType) {
                    case "FAX番号":
                        if (
                            typeof value === "undefined" ||
                            value.length === 0
                        ) {
                            throw context.createError({
                                message:
                                    "こちらの入力は必須です。" +
                                    (context.options.context?.state.loginInfo
                                        .copyFromCustomerInfo
                                        ? `上の「お客様情報」セクションでご記入いただくか、「${Labels.loginInfo.copyFromCustomerInfo}」のチェックを外してご記入ください。`
                                        : ""),
                            });
                        }
                        break;

                    case "メールアドレス":
                    case "入力不可":
                        if (typeof value !== "undefined" && value.length > 0) {
                            throw context.createError({
                                message: "こちらの入力は出来ません",
                            });
                        }
                        break;

                    case "任意":
                        // 何もしない
                        break;

                    default:
                        throw context.createError({
                            message: `プログラムエラーです。 loginIdType=${prodModel.loginIdType} は定義されていません。`,
                        });
                }

                return true;
            }),
        email: string<string | undefined>()
            .email("入力形式が誤っています。")
            .max(50)
            .test("loginInfoEmailTest", (value, context) => {
                const prodModel = useWebFormStore().prodModel();
                if (!prodModel) return;

                switch (prodModel.loginIdType) {
                    case "メールアドレス":
                        if (
                            typeof value === "undefined" ||
                            value.length === 0
                        ) {
                            throw context.createError({
                                message:
                                    "こちらの入力は必須です。" +
                                    (context.options.context?.state.loginInfo
                                        .copyFromCustomerInfo
                                        ? `上の「お客様情報」セクションでご記入いただくか、「${Labels.loginInfo.copyFromCustomerInfo}」のチェックを外してご記入ください。`
                                        : ""),
                            });
                        }
                        break;

                    case "FAX番号":
                    case "入力不可":
                        if (typeof value !== "undefined" && value.length > 0) {
                            throw context.createError({
                                message: "こちらの入力は出来ません。",
                            });
                        }
                        break;

                    case "任意":
                        // 何もしない
                        break;

                    default:
                        throw context.createError({
                            message: `プログラムエラーです。 loginIdType=${prodModel.loginIdType} は定義されていません。`,
                        });
                }

                return true;
            }),

        // 識別コード任意入力
        otherIdCode: string<string | undefined>()
            .default(undefined)
            .optional()
            .email("入力形式が誤っています。")
            .matches(EMAIL_OPTIONAL_PATTERN_REGEXP, "入力形式が誤っています。")
            .max(50),

        // 請求内訳コメント
        comment: string<string | undefined>()
            .max(20)
            .test("loginInfoCommentTest", (value, context) => {
                const prodModel = useWebFormStore().prodModel();
                if (!prodModel) return;

                if (prodModel.requiredLoginInfoComment) {
                    if (typeof value === "undefined" || value.length === 0) {
                        throw context.createError({
                            message: "こちらの入力は必須です。",
                        });
                    }
                } else if (prodModel.prohibitedLoginInfoComment) {
                    if (typeof value !== "undefined" && value.length > 0) {
                        throw context.createError({
                            message: "こちらの入力は出来ません。",
                        });
                    }
                }

                return true;
            }),
    },
    // 承認者情報
    approverInfo: {
        // 部署・氏名
        deptOrName: string()
            .trim()
            .max(50, "最大50文字です。")
            .test("approverInfoTest", (value, context) => {
                const state = context.options.context?.state;
                if (!state) {
                    throw context.createError({
                        message:
                            "プログラムエラーです。コードを見直してください。",
                    });
                }

                if (!value && state.approverInfo.email) {
                    throw context.createError({
                        message: `メールアドレス を入力した場合、こちらの入力は必須です。`,
                    });
                }

                return true;
            }),

        // メールアドレス
        email: string()
            .notRequired()
            .email("入力形式が誤っています。")
            .max(80, "最大80文字です。")
            .test("emailTest", (value, context) => {
                const state = context.options.context?.state;
                if (!state) {
                    throw context.createError({
                        message:
                            "プログラムエラーです。コードを見直してください。",
                    });
                }

                if (!value && state.approverInfo.deptOrName) {
                    throw context.createError({
                        message: `部署・氏名 を入力した場合、こちらの入力は必須です。`,
                    });
                }

                if (value && !EMAIL_PATTERN_REGEXP.test(value)) {
                    throw context.createError({
                        message: "入力形式が誤っています。",
                    });
                }

                if (!approverEmailIsUniq(state, "email")) {
                    return context.createError({
                        message: "メールアドレスが重複しています。",
                    });
                }

                return true;
            }),

            // 部署・氏名2
            deptOrName2: string()
            .trim()
            .max(50, "最大50文字です。")
            .test("approverInfoTest", (value, context) => {
                const state = context.options.context?.state;
                if (!state) {
                    throw context.createError({
                        message:
                            "プログラムエラーです。コードを見直してください。",
                    });
                }

                if (value && (!state.approverInfo.deptOrName || !state.approverInfo.email)) {
                    throw context.createError({
                        message: `お一人目の部署・氏名とメールアドレスを入力してください`,
                    });
                }

                if (!value && state.approverInfo.email2) {
                    throw context.createError({
                        message: `メールアドレス を入力した場合、こちらの入力は必須です。`,
                    });
                }

                return true;
            }),

            // メールアドレス2
            email2: string()
            .trim()
            .notRequired()
            .email("入力形式が誤っています。")
            .matches(EMAIL_OPTIONAL_PATTERN_REGEXP, "入力形式が誤っています。")
            .max(80, "最大80文字です。")
            .test("emailTest", (value, context) => {
                const state = context.options.context?.state;
                if (!state) {
                    throw context.createError({
                        message:
                            "プログラムエラーです。コードを見直してください。",
                    });
                }

                if (value && (!state.approverInfo.deptOrName || !state.approverInfo.email)) {
                    throw context.createError({
                        message: `お一人目の部署・氏名とメールアドレスを入力してください`,
                    });
                }

                if (!value && state.approverInfo.deptOrName2) {
                    throw context.createError({
                        message: `部署・氏名 を入力した場合、こちらの入力は必須です。`,
                    });
                }

                if (value && !EMAIL_PATTERN_REGEXP.test(value)) {
                    throw context.createError({
                        message: "入力形式が誤っています。",
                    });
                }

                if (!approverEmailIsUniq(state, "email2")) {
                    return context.createError({
                        message: "メールアドレスが重複しています。",
                    });
                }

                return true;
            }),
    },
    // 請求先情報
    billingInfo: {
        copyCustomerInfo: boolean().notRequired(),

        // 会社名
        companyName: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(50, "最大50文字です。"),

        // 会社名フリガナ
        companyNameKana: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(50, "最大50文字です。")
            .matches(KATAKANA_REGEXP, "入力形式が誤っています。"),

        // 部署名
        department: string().trim().max(50, "最大50文字です。"),

        // お名前
        nameLastName: string()
            .trim()
            .required("姓の入力は必須です。")
            .max(24, "最大24文字です。"),

        nameFirstName: string()
            .trim()
            .required("名の入力は必須です。")
            .max(24, "最大24文字です。"),

        // 電話番号
        phoneNumber: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(11, "最大11文字です。")
            .matches(PHONE_NUMBER_REGEXP, "入力形式が誤っています。"),

        // 郵便番号
        postalCode: string()
            .trim()
            .required("こちらの入力は必須です。")
            .min(7, "最小7文字です。")
            .max(8, "最大8文字です。")
            .matches(POSTALCODE_REGEXP, "入力形式が誤っています。"),

        // 住所
        addrPref: string().trim().required("こちらの入力は必須です。"),

        addrCity: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(100, "最大100文字です。"),

        addrStreet: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(50, "最大50文字です。"),

        addrOther: string().trim().max(50, "最大50文字です。"),

        // メールアドレス
        email: string()
            .trim()
            .required("こちらの入力は必須です。")
            .max(80, "最大80文字です。")
            .matches(EMAIL_PATTERN_REGEXP, "入力形式が誤っています。"),

        // // 部署名、プラン名など (=請求内訳コメント, C_Comment_Bill__c)
        // deptOrPlanNameOrEtc: string()
        //     .trim()
        //     .notRequired()
        //     .max(20, "最大20文字です。"),

        billingMethod: string().trim().required("選択してください。"),

        billingSendTo: string()
            .trim()
            .optional()
            .test("billingSendToTest", (value, context) => {
                console.debug("called billingSendTo test func");
                const state = context.options.context?.state;

                if (typeof state === "undefined") {
                    throw new Error(
                        "プログラムエラーです。検証時は context に次のデータを渡してください。: { state: WebFormState; force?: boolean; }"
                    );
                }
                if (state?.billingInfo.billingMethod === "Web請求") {
                    if (
                        state.billingInfo.billingMethod === "Web請求" &&
                        !value
                    ) {
                        return context.createError({
                            message:
                                "請求方法「Web請求」の場合「請求宛先」入力必要です。",
                        });
                    }
                }
                return true;
            }),

        otherEmail: string()
            .trim()
            .optional()
            .email("1番目の入力形式が誤っています。")
            .test("otherEmailTest", (value, context) => {
                console.debug("called otherEmail test func");
                const state = context.options.context?.state;
                const force = context.options.context?.force;

                if (typeof state === "undefined") {
                    throw new Error(
                        "プログラムエラーです。検証時は context に次のデータを渡してください。: { state: WebFormState; force?: boolean; }"
                    );
                }

                if (state?.billingInfo.billingSendTo === "その他") {
                    if (
                        state.billingInfo.billingSendTo === "その他" &&
                        !value
                    ) {
                        return context.createError({
                            message: "1番目の入力は必須です。",
                        });
                    }

                    if (!billingOtherEmailIsUniq(state, "otherEmail")) {
                        return context.createError({
                            message: "メールアドレスが重複しています。",
                        });
                    }
                }

                return true;
            }),
        otherEmail2: string()
            .trim()
            .optional()
            .email("2番目の入力形式が誤っています。")
            .test("otherEmail2Test", (value, context) => {
                const state = context.options.context?.state;
                const force = context.options.context?.force;

                if (typeof state === "undefined") {
                    throw new Error(
                        "プログラムエラーです。検証時は context に次のデータを渡してください。: { state: WebFormState; force?: boolean; }"
                    );
                }

                if (state?.billingInfo.billingSendTo === "その他") {
                    if (!billingOtherEmailIsUniq(state, "otherEmail2")) {
                        return context.createError({
                            message: "メールアドレスが重複しています。",
                        });
                    }

                    if (!billingOtherEmailIsInOrdered(state, "otherEmail2")) {
                        return context.createError({
                            message: OTHER_EMAILS_INPUT_ORDER_ERROR_MESSAGE,
                        });
                    }
                }

                return true;
            }),
        otherEmail3: string()
            .trim()
            .optional()
            .email("3番目の入力形式が誤っています。")
            .test("otherEmail3Test", (value, context) => {
                const state = context.options.context?.state;
                const force = context.options.context?.force;

                if (typeof state === "undefined") {
                    throw new Error(
                        "プログラムエラーです。検証時は context に次のデータを渡してください。: { state: WebFormState; force?: boolean; }"
                    );
                }

                if (state?.billingInfo.billingSendTo === "その他") {
                    if (!billingOtherEmailIsUniq(state, "otherEmail3")) {
                        return context.createError({
                            message: "メールアドレスが重複しています。",
                        });
                    }

                    if (!billingOtherEmailIsInOrdered(state, "otherEmail3")) {
                        return context.createError({
                            message: OTHER_EMAILS_INPUT_ORDER_ERROR_MESSAGE,
                        });
                    }
                }

                return true;
            }),
        otherEmail4: string()
            .trim()
            .optional()
            .email("4番目の入力形式が誤っています。")
            .test("otherEmail4Test", (value, context) => {
                const state = context.options.context?.state;
                const force = context.options.context?.force;

                if (typeof state === "undefined") {
                    throw new Error(
                        "プログラムエラーです。検証時は context に次のデータを渡してください。: { state: WebFormState; force?: boolean; }"
                    );
                }

                if (state?.billingInfo.billingSendTo === "その他") {
                    if (!billingOtherEmailIsUniq(state, "otherEmail4")) {
                        return context.createError({
                            message: "メールアドレスが重複しています。",
                        });
                    }

                    if (!billingOtherEmailIsInOrdered(state, "otherEmail4")) {
                        return context.createError({
                            message: OTHER_EMAILS_INPUT_ORDER_ERROR_MESSAGE,
                        });
                    }
                }

                return true;
            }),
        otherEmail5: string()
            .trim()
            .optional()
            .email("5番目の入力形式が誤っています。")
            .test("otherEmail5Test", (value, context) => {
                const state = context.options.context?.state;
                const force = context.options.context?.force;

                if (typeof state === "undefined") {
                    throw new Error(
                        "プログラムエラーです。検証時は context に次のデータを渡してください。: { state: WebFormState; force?: boolean; }"
                    );
                }

                if (state?.billingInfo.billingSendTo === "その他") {
                    if (!billingOtherEmailIsUniq(state, "otherEmail5")) {
                        return context.createError({
                            message: "メールアドレスが重複しています。",
                        });
                    }

                    if (!billingOtherEmailIsInOrdered(state, "otherEmail5")) {
                        return context.createError({
                            message: OTHER_EMAILS_INPUT_ORDER_ERROR_MESSAGE,
                        });
                    }
                }

                return true;
            }),
        paymentMethod: string().trim().required("選択してください。"),

        wantDownloadStatement: boolean().notRequired(),

        wantSplitWithStatementSplittingCode: boolean().notRequired(),

        // userNote: string().trim().notRequired().max(255, "最大255文字です。"),
    },
};
